// General
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// Hooks
import { useDispatch, useSelector } from 'react-redux';

// UI Kit
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Components
import MyCaregiversCardList from 'display-components/family-management/my-caregivers-card-list';

import { BirdiModalHeaderDanger, BirdiModalHeaderWithBackgroundColor } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    familyAccountGetCaregiversRoutine,
    familyAccountPostChangeCaregiversAccessRoutine
} from 'state/family-account/family-account.reducer';
// State
import {
    familyAccountCaregiversSelector,
    familyAccountIsCaregiverLoadingSelector
} from 'state/family-account/family-account.selectors';

// Types
import { Caregiver } from 'types/family-management';

import { getPhoneNumber } from 'util/globalVariables';
// Util
import { capitalizeFirstLetter } from 'util/string';

// Main component
const CaregiversRequestList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const caregivers = useSelector(familyAccountCaregiversSelector);
    const isCaregiversLoading = useSelector(familyAccountIsCaregiverLoadingSelector);

    // This state will depend if there is at least one pending response
    const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const handleAccept = useCallback(
        (caregiver: Caregiver) => {
            setIsBusy(true);
            dispatch(
                familyAccountPostChangeCaregiversAccessRoutine.trigger({
                    data: {
                        accountStatus: 'GRANT',
                        dependentId: caregiver.id
                    },
                    onSuccess: () => {
                        setIsBusy(false);
                        dispatch(familyAccountGetCaregiversRoutine.trigger());
                        dispatch(
                            openModal({
                                showClose: false,
                                type: 'primary',
                                size: 'lg',
                                headerContent: (
                                    <BirdiModalHeaderWithBackgroundColor
                                        headerText={t('modals.familyAccount.caregiverAccept.title')}
                                    />
                                ),
                                bodyContent: (
                                    <BirdiModalContentAlt
                                        subTitle={t('modals.familyAccount.caregiverAccept.body', {
                                            caregiverName: capitalizeFirstLetter(caregiver.firstName.toLowerCase())
                                        })}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.familyAccount.caregiverAccept.button'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    },
                    onFailure: (response: string) => {
                        setIsBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                type: 'danger',
                                size: 'lg',
                                headerContent: (
                                    <BirdiModalHeaderDanger
                                        icon="alert"
                                        headerText={t('modals.familyAccount.caregiverGenericError')}
                                    />
                                ),
                                bodyContent: (
                                    <BirdiModalContentAlt
                                        subTitle={
                                            response.indexOf('is already being managed')
                                                ? t('modals.familyAccount.caregiverChangeAccessAlreadyMemberError')
                                                : t('modals.familyAccount.caregiverChangeAccessError')
                                        }
                                        note={t('modals.familyAccount.furtherAssistance', {
                                            phoneNumber: getPhoneNumber({ isEnd: true })
                                        })}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.default.submit'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        },
        [dispatch, t]
    );

    const handleDecline = useCallback(
        (caregiver: Caregiver) => {
            dispatch(
                openModal({
                    showClose: false,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('modals.familyAccount.caregiverDecline.title')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={t('modals.familyAccount.caregiverDecline.body', {
                                caregiverName: capitalizeFirstLetter(caregiver.firstName.toLowerCase())
                            })}
                        />
                    ),
                    ctas: [
                        {
                            label: t('modals.familyAccount.caregiverDecline.cancelButton'),
                            variant: 'link',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'FamilyAccountProfile'
                        },
                        {
                            label: t('modals.familyAccount.caregiverDecline.decline'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                                setIsBusy(true);
                                dispatch(
                                    familyAccountPostChangeCaregiversAccessRoutine.trigger({
                                        data: {
                                            accountStatus: 'DECLINE',
                                            dependentId: caregiver.id
                                        },
                                        onSuccess: () => {
                                            setIsBusy(false);
                                            dispatch(familyAccountGetCaregiversRoutine.trigger());
                                        },
                                        onFailure: () => {
                                            setIsBusy(false);
                                            dispatch(
                                                openModal({
                                                    showClose: true,
                                                    type: 'danger',
                                                    size: 'lg',
                                                    headerContent: (
                                                        <BirdiModalHeaderDanger
                                                            icon="alert"
                                                            headerText={t('modals.familyAccount.caregiverGenericError')}
                                                        />
                                                    ),
                                                    bodyContent: (
                                                        <BirdiModalContentAlt
                                                            subTitle={t(
                                                                'modals.familyAccount.caregiverChangeAccessError'
                                                            )}
                                                        />
                                                    ),
                                                    ctas: [
                                                        {
                                                            label: t('modals.default.submit'),
                                                            variant: 'primary',
                                                            onClick: () => {
                                                                dispatch(closeModal({}));
                                                            }
                                                        }
                                                    ]
                                                })
                                            );
                                        }
                                    })
                                );
                            },
                            dataGALocation: 'FamilyAccountProfile'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const caregiversPayload = caregivers.map((caregiver) => {
        const showCtaButtons = caregiver.accountStatus === 'responsePending';

        return {
            ...caregiver,
            onAccept: showCtaButtons ? () => handleAccept(caregiver) : undefined,
            onDecline: showCtaButtons ? () => handleDecline(caregiver) : undefined,
            isBusy: isBusy
        };
    });

    useEffect(() => {
        dispatch(familyAccountGetCaregiversRoutine.trigger());
    }, [dispatch]);

    useEffect(() => {
        if (caregivers.length) {
            const hasResponsePending = caregivers.some((caregiver) => caregiver.accountStatus === 'responsePending');
            setShowDisclaimer(hasResponsePending);
        }
    }, [caregivers, dispatch]);

    return (
        <Container fluid>
            <Row>
                <Col className="px-0 mb-3">
                    {isCaregiversLoading ? (
                        <LoadingMessage
                            text={t(`pages.profile.familyAccount.familySection.loadingMessage`)}
                            isVisible={isCaregiversLoading}
                        />
                    ) : caregivers.length > 0 ? (
                        <MyCaregiversCardList payload={caregiversPayload} />
                    ) : (
                        <p className="profile-empty-message text-center">
                            {t('pages.profile.familyAccount.myCaregiversSection.emptyMessage')}
                        </p>
                    )}
                </Col>
            </Row>
            <Row>
                {showDisclaimer && (
                    <ToastBox
                        variant="descriptive"
                        children={<span>{t('pages.familyAccount.manage.disclaimer')}</span>}
                    />
                )}
            </Row>
        </Container>
    );
};

export default CaregiversRequestList;
