import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useMemo } from 'react';

//Ui-kit
import BorderedContainer from 'ui-kit/bordered-container/bordered-container';
import Button from 'ui-kit/button/button';
// Interfaces and types
import { IconBackgroundColorProps } from 'ui-kit/icon-background-color';
import IconBackgroundColor from 'ui-kit/icon-background-color';
import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import ClockCircleIcon from 'ui-kit/icons/clock-circle';
// Ui-kit: icons
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';
import UserCircleIcon from 'ui-kit/icons/user-circle';
import ToastBox from 'ui-kit/toast-box/toast-box';
import TooltipContainer from 'ui-kit/tooltip-container';

import {
    MyCaregiversCardActionProps,
    MyCaregiversCardIconProps,
    MyCaregiversCardProps,
    MyCaregiversCardTooltipProps
} from 'types/family-management';

import FamilyManagementBadgeStatus from '../family-management-badge-status';
// Sub-components
import FamilyMember from '../family-member';
// Styles
import './my-caregivers.style.scss';

// Helper function to choose the card icon depending on the combination of
// account status and account type.
const MyCaregiversCardIcon = ({ accountStatus, isMembership }: MyCaregiversCardIconProps): ReactElement => {
    switch (true) {
        case accountStatus === 'responsePending':
            return <ClockCircleIcon />;
        case accountStatus === 'declined' && isMembership:
            return <AlertIcon variant="alert" />;
        default:
            return <UserCircleIcon />;
    }
};

// Helper function to show or not a tooltip message.
const MyCaregiversCardTooltip = ({ accountStatus, t }: MyCaregiversCardTooltipProps): ReactElement => {
    if (!accountStatus || (accountStatus !== 'fullAccess' && accountStatus !== 'partialAccess')) {
        return <></>;
    }

    const tooltipId = (Math.floor(Math.random() * 6) + 1).toString();
    const tooltipMessage = t(`components.familyManagement.tooltip.myCaregivers.${accountStatus}`);

    return (
        <TooltipContainer tip={tooltipMessage} id={`my-caregivers-card-tooltip-${tooltipId}`} placement="top-start">
            <div className="icon">
                <CircleInfo />
            </div>
        </TooltipContainer>
    );
};

// Helper function to choose the correct icon background variant based on the account status.
// As these status are not totally the same as the badge status, it was decided to have two
// different functions, even if they are very similar.
const getIconBackgroundColor = (
    accountStatus: MyCaregiversCardProps['accountStatus']
): IconBackgroundColorProps['variant'] => {
    switch (accountStatus) {
        case 'expired':
            return 'warning';
        case 'declined':
            return 'danger';
        default:
            return 'primary';
    }
};

// Helper function to choose the correct text for the Granted/Requested date text.
const getLastUpdateDateText = (accountStatus: MyCaregiversCardProps['accountStatus']): string => {
    if (accountStatus === 'fullAccess' || accountStatus === 'partialAccess' || accountStatus === 'accepted') {
        return 'granted';
    }

    return 'requested';
};

// Helper function to display the correct text for the note.
const getNoteText = (
    accountStatus: MyCaregiversCardProps['accountStatus'],
    note: MyCaregiversCardProps['note'],
    isMembership: MyCaregiversCardProps['isMembership']
): string => {
    if (accountStatus === 'expired') {
        let expiredMarker = 'expired';

        if (isMembership) {
            expiredMarker = 'expiredMembership';
        }

        return (note = `components.familyManagement.caregiverInfo.note.${expiredMarker}`);
    }

    return '';
};

// Helper function to avoid duplicate the implementation of this component
const MyCaregiversCardAction = ({
    onRevokeAccessAction,
    onDeclineAction,
    onAcceptAction,
    t,
    isBusy
}: MyCaregiversCardActionProps): ReactElement => {
    return (
        <>
            {onRevokeAccessAction && (
                <div className="my-caregivers-card__actions__action-item">
                    <Button
                        label={t('components.familyManagement.actions.revokeAccess')}
                        type="button"
                        variant="text"
                        onClick={() => onRevokeAccessAction()}
                        isBusy={isBusy}
                        disabled={isBusy}
                        async
                    />
                </div>
            )}
            {onDeclineAction && (
                <div className="my-caregivers-card__actions__action-item">
                    <Button
                        label={t('components.familyManagement.actions.decline')}
                        type="button"
                        variant="text"
                        onClick={() => onDeclineAction()}
                        isBusy={isBusy}
                        disabled={isBusy}
                        async
                    />
                </div>
            )}
            {onAcceptAction && (
                <div className="my-caregivers-card__actions__action-item">
                    <Button
                        label={t('components.familyManagement.actions.accept')}
                        type="button"
                        onClick={() => onAcceptAction()}
                        isBusy={isBusy}
                        disabled={isBusy}
                        async
                    />
                </div>
            )}
        </>
    );
};

// Main component
const MyCaregiversCard = ({
    caregiverName,
    accountStatus,
    note,
    lastUpdateDate,
    className,
    isBusy,
    isMembership,
    hideActions = false,
    onDecline,
    onAccept,
    onRevokeAccess
}: MyCaregiversCardProps): ReactElement => {
    const containerClasses = classNames('my-caregivers-card', { [`${className}`]: className });
    const { t } = useTranslation();

    const hasAction = useMemo(() => {
        const hasActionButton = !!onRevokeAccess || !!onDecline || !!onAccept;

        return !hideActions && accountStatus !== 'expired' && hasActionButton;
    }, [onRevokeAccess, onDecline, onAccept, accountStatus, hideActions]);

    return (
        <BorderedContainer className={containerClasses}>
            <div className="my-caregivers-card__body">
                <div className="my-caregivers-card__mobile-content">
                    <FamilyMember name={caregiverName} />
                </div>
                {accountStatus === 'expired' && (
                    <div className="my-caregivers-card__note-container">
                        <ToastBox variant={accountStatus === 'expired' ? 'warning' : undefined}>
                            {t(getNoteText(accountStatus, note, isMembership))}
                        </ToastBox>
                    </div>
                )}
                <div className="my-caregivers-card__content">
                    <div className="my-caregivers-card__content__icon-container">
                        <IconBackgroundColor variant={getIconBackgroundColor(accountStatus)}>
                            <MyCaregiversCardIcon accountStatus={accountStatus} isMembership={isMembership} />
                        </IconBackgroundColor>
                    </div>
                    <div className="my-caregivers-card__content__caregiver-info">
                        <div className="my-caregivers-card__desktop-only">
                            <FamilyMember name={caregiverName} />
                        </div>

                        <div className="account-status">
                            {accountStatus === 'accepted' ? (
                                <></>
                            ) : (
                                <div className="label">
                                    {t('components.familyManagement.accountInfo.accountStatus')}
                                </div>
                            )}
                            <div className="value">
                                <FamilyManagementBadgeStatus accountStatus={accountStatus} />
                                <MyCaregiversCardTooltip accountStatus={accountStatus} t={t} />
                            </div>
                        </div>
                        <div className="last-update-date">
                            {t(
                                `components.familyManagement.accountInfo.lastUpdateDate.${getLastUpdateDateText(
                                    accountStatus
                                )}`,
                                {
                                    date: lastUpdateDate
                                }
                            )}
                        </div>
                    </div>
                    {hasAction && (
                        <div className="my-caregivers-card__content__actions">
                            <MyCaregiversCardAction
                                onRevokeAccessAction={onRevokeAccess}
                                onDeclineAction={onDecline}
                                onAcceptAction={onAccept}
                                t={t}
                                isBusy={isBusy}
                            />
                        </div>
                    )}
                </div>
                {hasAction && (
                    <div className="my-caregivers-card__mobile-content__actions">
                        <MyCaregiversCardAction
                            onRevokeAccessAction={onRevokeAccess}
                            onDeclineAction={onDecline}
                            onAcceptAction={onAccept}
                            t={t}
                            isBusy={isBusy}
                        />
                    </div>
                )}
            </div>
        </BorderedContainer>
    );
};

export default MyCaregiversCard;
