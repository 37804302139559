import { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components & UI
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';
import InternalHeader from 'components/internal-header/internal-header.component';
import CaregiversRequestList from 'components/caregivers-request-list';
import { lowercaseAndCapitalize } from 'util/cart';
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';

// Redux
import {
    familyAccountFirstNameSelector,
    familyAccountUserBearerTokenSelector,
    familyAccountExpiredSelector
} from 'state/family-account/family-account.selectors';

const ManageFamilyAccount = ({ data }: { data: GatsbyTypes.FamilyAccountManageQuery }) => {
    const { blueSkyBackground } = data;
    const { t } = useTranslation();
    const bearerToken = useSelector(familyAccountUserBearerTokenSelector);
    const firstName = useSelector(familyAccountFirstNameSelector);
    const isExpired = useSelector(familyAccountExpiredSelector);

    useEffect(() => {
        if (!bearerToken || isExpired) {
            navigate('/link-expired?flow=caregiver-access');
        }
    }, [bearerToken, isExpired]);

    return (
        <MarketingPageLayout
            headerImage={blueSkyBackground}
            headerImageClassName="profile-background"
            metaData={{ nodeTitle: 'Family Account' }}
            suppressApplicationPage={true}
        >
            <InternalHeader
                title={t('pages.familyAccount.manage.headlineText')}
                eyebrowText={t('pages.familyAccount.manage.eyebrowText')}
                sectionIndex={0}
                body={t('pages.familyAccount.manage.greeting', {
                    firstName: lowercaseAndCapitalize(firstName || '')
                })}
            />

            <Container fluid="xl" className={`easy-refill-prescriptions`}>
                <Row className="justify-content-lg-center">
                    <Col xs={12} md={12} lg={9} className="familyAccount-manage">
                        <CaregiversRequestList />
                    </Col>
                </Row>
            </Container>
        </MarketingPageLayout>
    );
};

export default withUnauthenticatedSessionExpiration(ManageFamilyAccount, 'caregiver-access');

export const query = graphql`
    query FamilyAccountManage($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background-02.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
