import { ReactElement } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Interfaces and types
import UIStatuses from 'types/ui-statuses';
import { FamilyManagementBadgeStatusProps } from 'types/family-management';

// Ui-kit
import BadgeStatus from 'ui-kit/bagde/badge-status';

// Main component
const FamilyManagementBadgeStatus = ({ accountStatus }: FamilyManagementBadgeStatusProps): ReactElement => {
    const { t } = useTranslation();
    let badgeStatusVariant: UIStatuses = 'primary';

    switch (accountStatus) {
        case 'invitePending':
            badgeStatusVariant = 'info';
            break;
        case 'fullAccess':
            badgeStatusVariant = 'success';
            break;
        case 'accepted':
            badgeStatusVariant = 'success';
            break;
        case 'expired':
            badgeStatusVariant = 'warning';
            break;
        case 'declined':
            badgeStatusVariant = 'danger';
            break;
        default:
            badgeStatusVariant = 'primary';
            break;
    }

    return (
        <BadgeStatus
            label={t(`components.familyManagement.badgeStatuses.${accountStatus}`)}
            variant={badgeStatusVariant}
        />
    );
};

export default FamilyManagementBadgeStatus;
