import { ReactElement } from 'react';
import classNames from 'classnames';

// Interfaces and types
import { MyCaregiversCardListProps } from 'types/family-management';

// Components
import MyCaregiversCard from '../my-caregivers-card';

//Styles
import './my-caregivers-card-list.style.scss';

const MyCaregiversCardList = ({ payload, className }: MyCaregiversCardListProps): ReactElement => {
    const containerClasses = classNames('my-caregivers-card-list', { [`${className}`]: className });

    return (
        <div className={containerClasses}>
            <div className="my-caregivers-card-list__container-list">
                {payload &&
                    payload.map((caregiver, index) => (
                        <div key={`my-caregiver-card-item-${index}`}>
                            <MyCaregiversCard {...caregiver} />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default MyCaregiversCardList;
