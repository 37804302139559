import { ReactElement } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';
import OverlayTrigger, { OverlayTriggerProps, OverlayTriggerType } from 'react-bootstrap/OverlayTrigger';
import ReactTooltip from 'react-bootstrap/Tooltip';

import './tooltip-container.style.scss';

interface TooltipContainerProps {
    tip: string;
    id: string;
    children: ReactElement;
    placement?: OverlayTriggerProps['placement'];
    trigger?: OverlayTriggerType;
    className?: string;
}

const TooltipContainer = ({
    tip,
    id,
    placement = 'auto-start',
    trigger,
    children,
    className
}: TooltipContainerProps) => {
    const containerClasses = classNames('tooltip-container', { [`${className}`]: className });
    const { t } = useTranslation();

    return (
        <div className={containerClasses}>
            <OverlayTrigger
                trigger={trigger}
                placement={placement}
                overlay={<ReactTooltip id={`tooltip-container-${id}`}>{tip}</ReactTooltip>}
            >
                <button
                    className="tooltip-container__children-container"
                    type="button"
                    aria-label={t('components.tooltip.buttonText')}
                >
                    {children}
                </button>
            </OverlayTrigger>
        </div>
    );
};

export default TooltipContainer;
